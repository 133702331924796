<template>
  <div class="row mx-2 settings-page px-lg-4 py-4">
    <div class="col-xl-4 order-lg-12">
      <SettingsSidebar />
    </div>
    <div class="col">
      <label>Ayala Sales File Setting</label>

      <div class="tab-panel" id="no-selected" role="tabpanel">
        <div class="card asf-card">
          <div class="card-body">
            <h3 class="card-title px-3">Ayala Network</h3>

            <div class="form-group px-3">
              <label>Drive path</label>
              <input type="text" class="form-control" placeholder="Ex. \\HOSTNAME\folder\path\here"
                v-model="textSettings.unc" @blur="validate('textUnc', textSettings.unc)"
                :class="{'is-invalid': validations.textUnc == false}">
            </div>

            <div class="d-flex">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Username</label>
                  <input type="text" class="form-control" placeholder="Enter username"
                    v-model="textSettings.username">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Password</label>
                  <input type="password" class="form-control" placeholder="Enter password"
                  v-model="textSettings.password">
                </div>
              </div>
            </div>
          </div> <!-- /.card-body -->
        </div> <!-- /.card -->

        <div class="card asf-card">
          <div class="card-body">
            <h3 class="card-title px-3">Laptop</h3>
            
            <div class="form-group px-3">
              <label>Ayala Tenant API OUTGOING network folder path</label>
              <input type="text" class="form-control" placeholder="Ex. \\HOSTNAME\folder\path\here"
                v-model="csvSettings.unc" @blur="validate('csvUnc', csvSettings.unc)"
                :class="{'is-invalid': validations.csvUnc == false}">
            </div>

            <div class="d-flex">
              <div class="col-md-6">
                <label>Username</label>
                <input type="text" class="form-control" placeholder="Enter username"
                  v-model="csvSettings.username">
              </div>
              <div class="col-md-6">
                <label>Password</label>
                <input type="password" class="form-control" placeholder="Enter password"
                  v-model="csvSettings.password">
              </div>
            </div>
          </div> <!-- /.card-body -->
        </div> <!-- /.card -->

        <div class="d-flex justify-content-end asf-button-row">
          <button class="btn btn-secondary"
            @click="test()" :disabled="!isFormValid()">
            Test
          </button>
          <button class="btn btn-primary"
            @click="save()" :disabled="!isFormValid()">
            Save
          </button>
        </div>

        <hr>

        <div class="card asf-card">
          <div class="card-body">
            <h3 class="card-title px-3">Regenerate and Resend</h3>
            <!-- Date Dropdown -->
            <div class="form-group px-3">
              <label>Reprocess Date</label>
              <select name="reprocessDate" class="custom-select" v-model="selectedDate">
                <option v-for="rdate in reprocessableDates" :value="rdate">{{ rdate }}</option>
              </select>
            </div>
            <div class="d-flex justify-content-end asf-button-row">
              <button class="btn btn-primary" type="button"
                :disabled="!selectedDate" @click="doRegenerate()">
                Reprocess
              </button>
            </div>
            <pre>{{ selectedDate }}</pre>
          </div>
        </div> <!-- /.card.asf-card -->
      </div>
    </div>
  </div>
</template>
<script>
import SettingsSidebar from '@/spa/components/common/SettingsSidebar';
import { makeUncFromParts, parseUncPath } from '../../../mobile_bridge/offload/unc';
import { SalesFileUtilityBridge } from '../../../mobile_bridge/offload/accreditations';

export default {
  components: {
    SettingsSidebar
  },
  data() {
    return {
      textSettings: {
        unc: '',
        username: '',
        password: ''
      },
      csvSettings: {
        unc: '',
        username: '',
        password: ''
      },
      validations: {
        textUnc: null,
        csvUnc: null
      },
      reprocessableDates: [],
      selectedDate: null,
    };
  },
  async mounted() {
    const storage = new ScopedNativeStorage(window.locationId);
    const sfub = new SalesFileUtilityBridge();

    const csvHost = await storage.get('SALES_FILE_CSV_HOST');
    const csvShare = await storage.get('SALES_FILE_CSV_SHARE');
    const csvPath = await storage.get('SALES_FILE_CSV_PATH');
    
    if (csvHost && csvShare) {
      this.csvSettings.unc = makeUncFromParts(csvHost, csvShare, csvPath);
      this.validate('csvUnc', this.csvSettings.unc);
    }

    this.csvSettings.username = await storage.get('SALES_FILE_CSV_USERNAME');
    this.csvSettings.password = await storage.get('SALES_FILE_CSV_PASSWORD');

    const textHost = await storage.get('SALES_FILE_TEXT_HOST');
    const textShare = await storage.get('SALES_FILE_TEXT_SHARE');
    const textPath = await storage.get('SALES_FILE_TEXT_PATH');

    if (textHost && textShare) {  
      this.textSettings.unc = makeUncFromParts(textHost, textShare, textPath);
      this.validate('textUnc', this.textSettings.unc);
    }

    this.textSettings.username = await storage.get('SALES_FILE_TEXT_USERNAME');
    this.textSettings.password = await storage.get('SALES_FILE_TEXT_PASSWORD');

    this.reprocessableDates = await sfub.getReprocessableDays();
  },
  methods: {
    showTestSuccessModal() {
      Swal.fire({
        title: 'Test Successful!',
        text: 'Mosaic POS was able to connect to both shared folders',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    },
    showSaveSuccessModal() {
      Swal.fire({
        title: 'Success!',
        text: 'Ayala Sales File settings have been saved.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    },
    showUnsupportedModal() {
      Swal.fire({
        title: 'Not supported on PWA',
        text: 'The Ayala Sales File feature is only available in the app.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    },
    validate(field, value) {
      const parsed = !!parseUncPath(value);

      this.validations[field] = parsed;
    },
    isFormValid() {
      // We intentionally not check the usernames and passwords. A Samba share
      // can be set up as passwordless.
      return this.validations.textUnc && this.validations.csvUnc;
    },
    async test() {
      if (!window.MosaicPosAppVersionNumbers) {
        this.showUnsupportedModal();
        return;
      }

      const salesFileUtil = new SalesFileUtilityBridge();
      console.log('Testing network share connection...');
      const results = await salesFileUtil.testNetworkShareSettings();

      console.log('Network share test results:', results);
      const { textSuccess, csvSuccess } = results;
      let title, message, alertType;

      if (textSuccess && csvSuccess) {
        this.showTestSuccessModal();
        return;
      } else if (textSuccess && !csvSuccess) {
        title = 'Check Laptop Shared Folder Settings';
        message = 'Mosaic POS was unable to connect to the laptop shared folder.';
        alertType = 'warning';
      } else if (!textSuccess && csvSuccess) {
        title = 'Check Ayala Shared Folder Settings';
        message = 'Mosaic POS was unable to connect to the Ayala shared folder.';
        alertType = 'warning';
      } else {
        title = 'Unable to connect!';
        message = 'Mosaic POS was unable to connect to both shared folders. ';
        alertType = 'error';
      }

      Swal.fire({ title, text: message, icon: alertType });
    },
    async save() {
      if (ScopedNativeStorage.isSupported()) {
        const storage = new ScopedNativeStorage(window.locationId);

        var textUncParts = parseUncPath(this.textSettings.unc);
        var csvUncParts = parseUncPath(this.csvSettings.unc);

        await storage.put('SALES_FILE_CSV_HOST', csvUncParts.host);
        await storage.put('SALES_FILE_CSV_SHARE', csvUncParts.share);
        await storage.put('SALES_FILE_CSV_PATH', csvUncParts.path);
        await storage.put('SALES_FILE_CSV_USERNAME', this.csvSettings.username);
        await storage.put('SALES_FILE_CSV_PASSWORD', this.csvSettings.password);

        await storage.put('SALES_FILE_TEXT_HOST', textUncParts.host);
        await storage.put('SALES_FILE_TEXT_SHARE', textUncParts.share);
        await storage.put('SALES_FILE_TEXT_PATH', textUncParts.path);
        await storage.put('SALES_FILE_TEXT_USERNAME', this.textSettings.username);
        await storage.put('SALES_FILE_TEXT_PASSWORD', this.textSettings.password);

        this.showSaveSuccessModal();
      } else {
        this.showUnsupportedModal();
      }
    },
    async doRegenerate() {
      if (!this.selectedDate) {
        console.log('No date is selected!');
        return;
      }


      window.__showLoader();
      const sfub = new SalesFileUtilityBridge();
      const result = await sfub.regenerateAndResendForDate(this.selectedDate);

      console.log('Result', result);
      window.__hideLoader();

      if (result.status == 200) {
        Swal.fire({
          title: 'Success!',
          text: 'Sales files regenerated and sent',
          icon: 'success',
          confirmButtonText: 'OK'
        });
      } else {
        Swal.fire({
          title: 'Problem encountered',
          text: 'There was a problem with regenerating sales files',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }      
    }
  }
}
</script>
<style scoped>
  .asf-card {
    box-shadow: 0px 4px 4px 0px #0000000D;
    margin-bottom: 16px;
  }

  .asf-card .card-title {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
  }

  .asf-card .card-body label {
    margin: 0 0 8px 0 !important;
  }

  .asf-button-row {
    margin-top: 16px;
    gap: 10px;
  }

  .asf-button-row .btn {
    min-width: 120px;
  }
</style>
