import { DataOffloadBridge } from './offload';

export class GenerateReportBridge extends DataOffloadBridge {
    constructor() {
        super('GenerateReport');
    }

    async getGeneratedReport(data) {
        return await this.sendMessage('GENERATE_REPORT', data);
    }

    async generateProductMixReport(data) {
        return await this.sendMessage('GENERATE_PRODUCT_MIX_REPORT', data);
    }

    async saveDayendData(data) {
        return await this.sendMessage('SAVE_DAYEND_DATA', data);
    }

    async saveAuditLog(data) {
        return await this.sendMessage('SAVE_AUDIT_LOG', data);
    }

    async shiftChangeSync(data) {
        return await this.sendMessage('SHIFT_CHANGE_SYNC', data);
    }
}