const regex = /^\\\\(?<host>[a-zA-Z0-9.-]+)\\(?<share>[a-zA-Z0-9-]+)(\\(?<path>[a-zA-Z0-9._\\-]*)?)?$/;

export const parseUncPath = (path) => {
  const match = path.match(regex);

  if (match && match.groups) {
    const { host, share, path = '' } = match.groups;
    
    return { host, share, path }
  }
}

export const makeUncFromParts = (host, share, path) => {
  return `\\\\${host}\\${share}${path ? `\\${path}` : ''}`
}
