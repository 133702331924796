import {DataOffloadBridge} from "@/mobile_bridge/offload/offload";
import {ACTIVE_POS_DATE, OFFLOAD} from "@/spa/constants";
import moment from "moment";
import {sqliteOffloadGetStorageValue} from "@/mobile_bridge/offload/receipt-model";

export class PosDateBridge extends DataOffloadBridge {
    constructor() {
        super('PosDate');
    }

    async getFromBackend(locationId) {
        const response = await axios.get(`/cashier/get-pos-date/${locationId}`);
        return response.data;
    }

    updateBackendPosDate(data) {
        try {
            axios.post(`/cashier/pos-date/`, data);
        } catch (error) {
            console.error('Error updating pos date:', error);
        }
    }

    async getRowById(locationId) {
        if (!OFFLOAD.sqliteOffloadPosDate) {
            return {};
        }

        return await this.sendMessage('GET_ROW_BY_ID', { locationId });
    }

    async getPosDate(locationId) {
        if (!OFFLOAD.sqliteOffloadPosDate) {
            const activePosDate = await sqliteOffloadGetStorageValue(ACTIVE_POS_DATE);
            return activePosDate?.pos_date ?? sessionStorage.getItem('posDate');
        }

        const posDateOperation = await this.getRowById(locationId);
        return posDateOperation?.pos_date ?? sessionStorage.getItem('posDate');
    }

    async updateAppPosDate(payload) {
        if (!OFFLOAD.sqliteOffloadPosDate) {
            return;
        }

        return await this.sendMessage('UPDATE', { payload });
    }

    async upsertPosDate(location_id) {
        try {
            const backendResponse = await this.getFromBackend(location_id);
            const appResponse = await this.getRowById(location_id);

            if (appResponse?.pos_date && !appResponse.pos_date.includes('00:00:00')) {
                appResponse.pos_date = appResponse.pos_date.split(' ')[0] + ' 00:00:00';
            }

            if (appResponse?.pos_date) {
                if (moment(backendResponse.pos_date).isSame(moment(appResponse.pos_date), 'day')) {
                    return appResponse.pos_date;
                }

                const formattedDates = {
                    appPosDate: appResponse.pos_date.split(' ')[0],
                    backendPosDate: backendResponse.pos_date.split(' ')[0],
                };

                const swalResult = await Swal.fire({
                    title: 'Please confirm business date',
                    icon: 'info',
                    html: `
                        <div>
                            <label class="mr-3">
                                <input type="radio" name="swal-option" value="${formattedDates.appPosDate}"> ${formattedDates.appPosDate}
                            </label>
                            <label>
                                <input type="radio" name="swal-option" value="${formattedDates.backendPosDate}"> ${formattedDates.backendPosDate}
                            </label>
                        </div>
                    `,
                    showCancelButton: false,
                    focusConfirm: false,
                    confirmButtonText: 'Submit',
                    allowOutsideClick: false,
                    preConfirm: () => {
                        const selectedOption = document.querySelector('input[name="swal-option"]:checked');
                        if (!selectedOption) {
                            Swal.showValidationMessage('Please choose an option');
                        } else {
                            return { option: selectedOption.value };
                        }
                    }
                });

                const isConfirmed = swalResult.isConfirmed;

                if (isConfirmed) {
                    const stb = new ShiftTableBridge();
                    const pos_date = swalResult.value.option + ' 00:00:00';
                    const payload = { location_id, pos_date };

                    await this.updateAppPosDate(payload);

                    this.updateBackendPosDate(payload);

                    const shiftTable = await stb.getRowById();
                    if (shiftTable?.local_id && shiftTable?.pos_date !== pos_date) {
                        await stb.resetShift(location_id, pos_date);
                    }

                    return pos_date;
                }

                return null;
            }

            await this.bulkImport([backendResponse]);

            return backendResponse.pos_date;
        } catch (error) {
            console.error('Error upserting POS date:', error);
            return null;
        }
    }
}
