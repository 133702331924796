import {
    ProductBridge,
    ProductCategoryBridge,
    ProductGroupBridge,
    PriceBridge,
    ReceiptDetailBridge,
    ServiceChannelBridge,
    TaxBridge,
    LocationBridge,
    PaymentTypeBridge
} from './offload';

import {
    ProductModifierBridge,
    ProductModifierPriceBridge
} from './product-modifier';

import {
    PrinterDetailBridge
} from './offload-printers';

import {
    UserPermissionBridge
} from './offload-permission';

import {
    ReportDataBridge,
    AccreditationBridge,
    AccreditationTypeBridge,
    TaxDetailBridge,
    TaxLocationBridge,
    TaxTypeBridge
} from './offload-reports';

import {
    PaymentMethodBridge
} from './payment-method';

function createLazyInstance(constructor) {
    let instance;

    return function () {
        if (!instance) {
            instance = new constructor();
        }
        return instance;
    };
}

export const sqliteOffloadBridges = {
    product_pricing: {
        lastFetchKey: 'productPricingLastFetchAt',
        bridge: createLazyInstance(PriceBridge)()
    },
    product_detail: {
        lastFetchKey: 'productDetailLastFetchAt',
        bridge: createLazyInstance(ProductBridge)()
    },
    product_categories: {
        lastFetchKey: 'productCategoryLastFetchAt',
        bridge: createLazyInstance(ProductCategoryBridge)()
    },
    product_groups: {
        lastFetchKey: 'productGroupLastFetchAt',
        bridge: createLazyInstance(ProductGroupBridge)()
    },
    modifier_detail: {
        lastFetchKey: 'modifierDetailLastFetchAt',
        bridge: createLazyInstance(ProductModifierBridge)()
    },
    modifier_pricing: {
        lastFetchKey: 'modifierPriceLastFetchAt',
        bridge: createLazyInstance(ProductModifierPriceBridge)()
    },
    own_printer_ips: {
        lastFetchKey: 'ownPrinterIpsLastFetchAt',
        bridge: createLazyInstance(PrinterDetailBridge)(),
    },
    receipt_detail: {
        lastFetchKey: 'receiptDetailLastFetchAt',
        bridge: createLazyInstance(ReceiptDetailBridge)()
    },
    service_channel: {
        lastFetchKey: 'serviceChannelLastFetchAt',
        bridge: createLazyInstance(ServiceChannelBridge)()
    },
    tax_detail: {
        lastFetchKey: 'taxLastFetchAt',
        bridge: createLazyInstance(TaxBridge)()
    },
    user_type_location_permissions: {
        lastFetchKey: 'userPermissionLastFetchAt',
        bridge: createLazyInstance(UserPermissionBridge)(),
    },
    locations: {
        lastFetchKey: 'locationLastFetchAt',
        bridge: createLazyInstance(LocationBridge)(),
    },
    payment_methods: {
        lastFetchKey: 'paymentMethodLastFetchAt',
        bridge: createLazyInstance(PaymentMethodBridge)(),
    },
    report_data: {
        lastFetchKey: 'reportDataLastFetchAt',
        bridge: createLazyInstance(ReportDataBridge)(), 
    },
    accreditations: {
        lastFetchKey: 'accreditationLastFetchAt',
        bridge: createLazyInstance(AccreditationBridge)(),
    },
    accreditation_types: {
        lastFetchKey: 'accreditationTypeLastFetchAt',
        bridge: createLazyInstance(AccreditationTypeBridge)(),
    },
    aligned_tax_details: {
        lastFetchKey: 'taxDetailLastFetchAt',
        bridge: createLazyInstance(TaxDetailBridge)(),
    },
    tax_locations: {
        lastFetchKey: 'taxLocationLastFetchAt',
        bridge: createLazyInstance(TaxLocationBridge)(),
    },
    tax_types: {
        lastFetchKey: 'taxTypeLastFetchAt',
        bridge: createLazyInstance(TaxTypeBridge)(),
    },
    payment_types: {
        lastFetchKey: 'paymentTypeLastFetchAt',
        bridge: createLazyInstance(PaymentTypeBridge)(),
    },
    report_reading: {
        lastFetchKey: 'reportReadingLastFetchAt',
        bridge: window.ReportReadingBridge,
    },
    location_pos: {
        lastFetchKey: 'locationPosLastFetchAt',
        bridge: window.LocationPosBridge,
    },
    report_day_end: {
        lastFetchKey: 'reportDayEndLastFetchAt',
        bridge: window.ReportDayEndBridge,
    },
};
