<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M8.64173 1.28237C9.7025 0.618704 10.9127 0.231442 12.1617 0.155969C13.4106 0.080496 14.6586 0.319217 15.7916 0.85032C16.9246 1.38142 17.9064 2.18797 18.6473 3.19628C19.3883 4.20458 19.8647 5.38249 20.0332 6.62237C21.3041 7.00326 22.3961 7.82787 23.1102 8.94609C23.8244 10.0643 24.1133 11.4018 23.9243 12.7151C23.7353 14.0284 23.081 15.2302 22.0806 16.1017C21.0801 16.9732 19.8 17.4564 18.4732 17.4635H18.468H18.4406L19.7057 16.0407C20.0069 15.7044 20.2387 15.312 20.3879 14.886C20.537 14.46 20.6006 14.0087 20.5749 13.558C20.5492 13.1074 20.4348 12.6662 20.2382 12.2599C20.0416 11.8535 19.7668 11.49 19.4293 11.1902C19.0919 10.8904 18.6986 10.6601 18.272 10.5126C17.8454 10.3652 17.3938 10.3034 16.9433 10.3309C16.4927 10.3583 16.052 10.4745 15.6465 10.6727C15.2409 10.8709 14.8785 11.1472 14.58 11.4858L9.81945 16.8395L8.9143 16.1624C8.54001 15.8817 8.11224 15.6807 7.65732 15.5715C7.20241 15.4624 6.72997 15.4475 6.26909 15.5278C5.80821 15.6081 5.36864 15.7819 4.97743 16.0384C4.58622 16.2949 4.25165 16.6288 3.9943 17.0195C2.68525 16.4941 1.58923 15.5464 0.88026 14.327C0.171285 13.1075 -0.110011 11.6862 0.0810319 10.2886C0.272075 8.89107 0.924506 7.59738 1.93474 6.61294C2.94498 5.62851 4.25512 5.00976 5.65716 4.85494C6.24763 3.37698 7.29193 2.1256 8.64173 1.28237Z"
        fill="#CCEFBE" fill-opacity="0.35"
      />
      <path
        d="M17.9966 12.8023C18.1229 12.9144 18.2259 13.0503 18.2997 13.2022C18.3735 13.3541 18.4166 13.519 18.4267 13.6876C18.4367 13.8562 18.4134 14.0251 18.3581 14.1847C18.3029 14.3443 18.2167 14.4914 18.1046 14.6177L11.2474 22.332C11.033 22.5731 10.7357 22.7247 10.4146 22.7568C10.0936 22.7888 9.77219 22.699 9.51427 22.5051L6.0857 19.9337C5.81291 19.7291 5.63256 19.4245 5.58434 19.087C5.53611 18.7494 5.62396 18.4065 5.82856 18.1337C6.03316 17.8609 6.33774 17.6806 6.6753 17.6324C7.01287 17.5841 7.35576 17.672 7.62856 17.8766L10.1108 19.7383L16.1811 12.9086C16.2934 12.7821 16.4294 12.679 16.5816 12.6052C16.7337 12.5315 16.8989 12.4884 17.0677 12.4785C17.2364 12.4686 17.4055 12.4922 17.5652 12.5477C17.7249 12.6033 17.8703 12.6898 17.9966 12.8023Z"
        fill="#CCEFBE"
      />
    </svg>
  </template>