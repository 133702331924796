import { DataOffloadBridge } from './offload';

export class BackupUtilityBridge extends DataOffloadBridge {
  constructor() {
    super('BackupUtility');
  }

  isSupported() {
    return window.MosaicPosAppSupportedFeatures?.uploadDatabase;
  }

  async listDatabaseFiles() {
    if (this.isSupported()) {
      return await this.sendMessage('LIST_DATABASE_FILES');
    }
  }

  async uploadDatabase() {
    if (this.isSupported()) {
      return await this.sendMessage('UPLOAD_DATABASE');
    }
  }

  async restoreFromBackup() {
    if (this.isSupported()) {
      return await this.sendMessage('RESTORE_FROM_BACKUP');
    }
  }
}
