<template>
    <div>
        <cash-float-modal
            v-model="isCashFloatModalOpen"
            :click-to-close="false"
            @save="saveCashFloatFromModal"
        />
        <div class="just-code-basic">
            <top-content v-if="!isOffline"/>
            <main-content />
            <bottom-content />
        </div>

        <m-blocker />
        <approver-modal />

        <PaymentQrCodeModal v-if="this.$route.name !== 'payments'" />
        <MultiterminalSyncHandler />
    </div>
</template>

<script>
import bus from '@/spa/utils/bus';
import { clearStorage } from '@/spa/plugins/vuex';
import {clearAxiosCache, isSqliteRequestLimitNotReached} from '@/spa/plugins/axios';
import { logout } from '@/spa/services/cashier-service';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import moment from 'moment';
import ApproverModal from '@/spa/components/modals/ApproverModal';
import MainContent from '@/spa/components/layouts/MainContent';
import TopContent from '@/spa/components/layouts/TopContent';
import BottomContent from '@/spa/components/layouts/BottomContent';
import MBlocker from '@/spa/components/common/MBlocker';
import MultiterminalSyncHandler from '@/spa/components/common/MultiterminalSyncHandler';
import { backgroundSync, storeSyncedOrder, storeSyncedSettlement, getSyncedOrders, updateSyncedOrder, voidBackgroundSync } from '@/spa/services/sync-service';
import { isEmpty, uniq, partition, uniqBy, differenceBy } from 'lodash';
import { checkCloudConnection } from '@/spa/plugins/axios';
import { trimSyncedOrder } from '@/spa/utils/object-trimmer';
import { subscribeToOrderUpdates } from '@/spa/services/sync-service';
import SyncStatus from "@/spa/global-mixins/sync-status";
import {
  SYNC_STATUSES,
  POST_BROADCAST_DELAY_SECONDS,
  DELETE_SETTLEMENT_ON_SYNC,
  ENABLE_LOCATION_LOGGING,
  ENABLE_BROADCAST_OPTIMIZATION,
  ENABLE_FORCE_SYNC,
  QUEUE_STATUSES,
  ENABLE_SALES_CONSOLIDATOR,
  ENABLE_CASH_FUND_IMPROVEMENT,
  OFFLOAD,
  ENABLE_FORCE_AUTO_LOGOUT_ON_SHIFT_CHANGE,
  SQLITE_OFFLOAD_SYNCING_INTERVAL,
  SQLITE_OFFLOAD_MAX_RECEIPTS_COUNT,
  SQLITE_INTEGRATION_SYNCING_INTERVAL,
  PERMISSIONS,
} from '@/spa/constants';
import { setIdleHandler } from '@/spa/utils/general';
import { calculateDiscountAmount, applyDiscount, redistributeTenders } from '@/spa/utils/computations';
import PrintMixin from "@/spa/components/mixins/PrintMixin";
import { getCashFloat, saveCashFloat, getLatestOrderId, getReceiptDetails } from "@/spa/services/cashier-service";
import CashFloatModal from '@/spa/components/modals/CashFloatModal.vue';
import PaymentQrCodeModal from "@/spa/components/modals/PaymentQrCodeModal";
import { storeAuditLog } from '@/spa/services/logger-service';
import { Logger } from "@/spa/helpers/Logger";
import {isNetworkStable} from "@/spa/utils/networkCheck";
import {TRANSACTION_STATUS_ID} from "@/mobile_bridge/offload/receipt-model";
import LabelPrinterMixin from "@/spa/components/mixins/LabelPrinterMixin";
import commonUtil from "@/spa/utils/common-util";

const getFetchFrequency = () => {
    if(window.isStandalone || window.isSharedTerminal) return 5;
    if(window.hasDeliveryIntegration || window.hasEPayment) return 3;
    return ENABLE_BROADCAST_OPTIMIZATION ? 0 : 60;
};

const GET_QUEUE_STATUS_FREQUENCY_SECONDS = 30;

const SYNC_FREQUENCY_SECONDS = 10;
const FETCH_FREQUENCY_SECONDS = getFetchFrequency();
const ENABLE_BACKGROUND_SYNC = true;
const MAX_PARALLEL_SYNC = 4;
const MIN_IDLE_BEFORE_SYNC_SECONDS = 5;
const APPLY_DISCOUNT_TO_KOT_BREAKDOWNS = false;
const USE_SPLIT_LOGIC_FOR_NON_SPLIT_ORDERS = false;
const POST_SETTLEMENT_SYNC_DELAY_SECONDS = 15;

export default {
    components: { ApproverModal, TopContent, MainContent, BottomContent, MBlocker, CashFloatModal, PaymentQrCodeModal, MultiterminalSyncHandler },

    mixins: [SyncStatus, PrintMixin, LabelPrinterMixin],

    data() {
        return {
            lastMtUpdateTimeStamp: new Date().toISOString(),
            lastRefreshTimestamp: new Date().getTime(),
            isIdle: false,
            terminal: JSON.parse(sessionStorage.getItem('terminal')),
            notificationAudio: new Audio("/sound/new-order-notification.mp3"),
            latestOrderId: 0,
            isOffline: false,
            isCashFloatModalOpen: false,
            isForceOffloadSyncStarted: false,
            isAyalaLocation: false,
        };
    },

    async beforeMount() {
        this.fetchMe().then(() => this.populatePermissions());
        await this.fetchCashFloat();
        await this.getReceiptDetails();
    },

    async mounted() {
        const locationBridge = new LocationBridge();
        const locations = await locationBridge.getAll();
        const location = locations[0];
        this.isAyalaLocation = location.accreditation_type_id == 2
        if (ENABLE_SALES_CONSOLIDATOR) {
            bus.on("executeLogoutIfPosDateMismatch", async (currentServerPosDate) => {
                if (!this.posDate) {
                    return;
                }

                if (currentServerPosDate && currentServerPosDate !== this.posDate) {
                    this.$store.commit('user/resetState');
                    this.$store.commit('global/resetState');
                    this.$store.commit('modals/resetState');
                    this.$store.commit('settings/resetState');
                    this.$store.commit('resetState');
                    sessionStorage.clear();
                    localStorage.clear();
                    await clearStorage();
                    await clearAxiosCache();
                    const dbs = await indexedDB.databases();
                    dbs.forEach(item => indexedDB.deleteDatabase(item.name));
                    await logout();

                    window.location.href = '/';
                }
            });
        }

        if (ENABLE_FORCE_AUTO_LOGOUT_ON_SHIFT_CHANGE) {
            bus.on("executeLogoutIfTerminalOnePerformsShiftChange", async (hasPerformShiftChange) => {
                if (hasPerformShiftChange) {
                    this.$store.commit('user/resetState');
                    this.$store.commit('global/resetState');
                    this.$store.commit('modals/resetState');
                    this.$store.commit('settings/resetState');
                    this.$store.commit('resetState');
                    sessionStorage.clear();
                    localStorage.clear();
                    await clearStorage();
                    await clearAxiosCache();
                    await cashierLogout();

                    window.location.href = '/cashier/home'
                }
            });
        }

        bus.on("watchToggleOfflineMode", (value) => {
            this.isOffline = value;
        });

        if (OFFLOAD.sqliteOffloadProduct) {
            await this.populateInitialData();
            this.setActiveBrandId(this.initialData?.brands[0]?.id || '');
        }

        if (OFFLOAD.sqliteOffloadReceipt) {
            const { PAID, VOIDED, ORIGINAL } = TRANSACTION_STATUS_ID;

            bus.on("forceOffloadSyncProcessStarted",(value) => {
                this.isForceOffloadSyncStarted = value
            });

            // Delete orders older than a certain date from SQLite orders table
            const orderBridge = new OrderBridge();
            await orderBridge.deleteOrdersOlderThan();

            // Fetch pending and billed order JSON from SQLite orders table
            await this.sqliteFetchPendingOrders();

            const rb = new ReceiptBridge();
            // Delete receipts and receipt_contents older than a certain business_date from SQLite
            await rb.deleteReceiptsOlderThan();

            setInterval(async () => {
                const receipts = OFFLOAD.useGetReceipts
                    ? await rb.getReceipts({
                      where: {
                        business_date: this.posDate,
                      },
                      whereNotEqual: {
                        is_syncing: 1
                      },
                      whereNull: ['synced_at'],
                      whereIn: {
                        transaction_status_id: `${PAID}, ${VOIDED}, ${ORIGINAL}`
                      },
                      limit: SQLITE_OFFLOAD_MAX_RECEIPTS_COUNT,
                    })
                    : await rb.getUnsyncedReceiptsSortedByDate();
                if (!this.isForceOffloadSyncStarted
                    && receipts.length > 0
                    && isSqliteRequestLimitNotReached()
                    && isNetworkStable()
                    && await checkCloudConnection()
                ) {
                    bus.emit("offloadSyncProcessStarted", true);
                    this.sqliteOffloadSync({ receipts });
                }
            }, SQLITE_OFFLOAD_SYNCING_INTERVAL * 1000);

            const storage = new ScopedNativeStorage(window.locationId);
            storage.put('appLatestId', 0);
            // POS Integration Orders
            setInterval(async () => {
                if (isNetworkStable() && (window.hasDeliveryIntegration || window.hasEPayment)) {
                    const response = await getLatestOrderId();
                    let latestOrderId = response.data ?? 0;

                    const appLatestId = await storage.get('appLatestId');

                    latestOrderId = appLatestId != 0 ? appLatestId : latestOrderId;

                    if (latestOrderId != this.latestOrderId) {
                        this.latestOrderId = latestOrderId;
                        storage.put('appLatestId', 0);
                        await this.getLatestIntegrationOrders();
                    }
                }
            }, SQLITE_INTEGRATION_SYNCING_INTERVAL * 1000);

            setInterval(() => {
                storage.put('appLatestId', new Date().getTime());
            }, 90 * 1000);

            return;
        }

        const isSatelliteTerminal = this.isMultiTerminal
            ? this.terminal.id !== 1
            : false;

        if(ENABLE_BACKGROUND_SYNC && !isSatelliteTerminal) {
            this.forceSyncOrders();
            this.resetIsSyncingFlag();
            const syncInterval = setInterval(() => {
                this.backgroundSynching();
                this.updateSyncing();
            }, SYNC_FREQUENCY_SECONDS * 1000);
            this.setSynchInterval(syncInterval);
        }

        this.getLatestServerOrders();
        if (FETCH_FREQUENCY_SECONDS > 0) {
            let isFetching = false;
            setInterval(async () => {
                if (isFetching) return;

                const currentTimestamp = new Date().getTime();
                if (currentTimestamp - this.lastBroadcastTimestamp < POST_BROADCAST_DELAY_SECONDS * 1000) return;

                if (window.isSharedTerminal || window.hasDeliveryIntegration || window.hasActiveBroadcast || window.hasEPayment) {
                    isFetching = true;
                    try {
                        const response = await getLatestOrderId();
                        const latestOrderId = response.data ?? 0;

                        if (latestOrderId != this.latestOrderId) {
                            this.latestOrderId = latestOrderId;
                            await this.getLatestServerOrders(this.lastMtUpdateTimeStamp);
                            this.bumpLastMtUpdateTimeStamp();
                        }
                    } catch (e) {
                        console.error(e);
                    }
                    isFetching = false;
                }
            }, FETCH_FREQUENCY_SECONDS * 1000);
        }

        if (ENABLE_BROADCAST_OPTIMIZATION && GET_QUEUE_STATUS_FREQUENCY_SECONDS > 0) {
            setInterval(() => this.getLatestQueueStatus(), GET_QUEUE_STATUS_FREQUENCY_SECONDS * 1000);
        }

        setIdleHandler(
            () => this.isIdle = true,
            MIN_IDLE_BEFORE_SYNC_SECONDS * 1000,
            () => this.isIdle = false,
        );

        setInterval(() => {
            if (!window.forceFetch) return;

            this.getLatestServerOrders(this.lastMtUpdateTimeStamp)
                .then(() => this.bumpLastMtUpdateTimeStamp())
                .then(() => window.forceFetch = false);
        }, 1000);
    },

    computed: {
        ...mapState([
            'settlements',
            'syncInterval',
            'orders',
            'lastBroadcastTimestamp',
            'latestDeliveryOrders',
            'latestCancelledDeliveryOrders',
            'latestOnlinePayment',
            'latestDineInOrders',
            'latestDineInOnlinePayment'
        ]),

        ...mapGetters(['targetOrder']),

        ...mapState('settings', ['posDate', 'hasCashFloat', 'terminalsCashFund']),
        ...mapState('user', ['terminalId']),

        ...mapState("global", ["showPaymentQRModal", "paymentQRDetails"]),
        ...mapState(['queueStatus']),
        ...mapState('sqlite', ['initialData']),

        isMultiTerminal() {
            return this.terminal?.is_shared_terminal;
        },

        hasCashFundPermission() {
            return ENABLE_CASH_FUND_IMPROVEMENT || (this.$can(this.PERMISSIONS.CASH_FUND_MANAGEMENT) || false);
        }
    },

    methods: {
        ...mapMutations([
            'setActiveBrandId',
            'deleteOrder',
            'setSynchInterval',
            'deleteSettlementByBillNum',
            'updateSettlementByBillNum',
            'updateOrder',
            'setSettlements',
        ]),

        ...mapMutations('settings', ['setCashFloat', 'setHasCashFloat', 'setTerminalsCashFund']),
        ...mapMutations('modals', ['setIsCashFloatModalOpen']),
        ...mapMutations('global', ['setSyncStatus', 'setSyncProgressPercent', 'setLatestDeliveryNotifications', 'setLatestCancelledDeliveryNotifications', 'setLatestOnlinePaymentNotifications', 'setPaymentQRDetails', 'setShowPaymentQRModal']),

        ...mapActions([
            'getOrderByBillNum',
            'getLatestServerOrders',
            'upsertOrder',
            'getLatestQueueStatus',
            'forceSyncOrders',
            'sqliteFetchPendingOrders',
            'sqliteOffloadSync',
            'getLatestIntegrationOrders',
        ]),

        ...mapActions('user', ['fetchMe', 'populatePermissions']),

        ...mapActions('sqlite', ['populateInitialData']),

        async fetchCashFloat(loadModal = true) {
            try {
                const response = await getCashFloat();
                let data = response.data;
                const terminal = JSON.parse(sessionStorage.getItem('terminal'))
                const currentTerminalId = this.hasCashFundPermission
                    ? terminal?.id || this.terminalId
                    : 1;

                const hasCashFloatData = !isEmpty(data) && data[currentTerminalId] != null;

                let terminalCashFund = {
                    ["Terminal " + currentTerminalId]: {
                        'Beginning Fund': 0,
                        'Additional Fund': 0,
                        'Withdraw': 0,
                    }
                };

                if (this.hasCashFundPermission && hasCashFloatData) {
                    terminalCashFund = {
                        ["Terminal " + currentTerminalId]: {
                            'Beginning Fund': data[currentTerminalId]['cash_float'] - data[currentTerminalId]['additional_cash'],
                            'Additional Fund': data[currentTerminalId]['additional_cash'],
                            'Withdraw': data[currentTerminalId]['cash_withdraw'],
                        }
                    };

                    data = {};
                    for (const t in response.data) {
                        data[t] = (response.data[t].cash_float - response.data[t].additional_cash);
                    }
                }

                if (this.$can(this.PERMISSIONS.SET_CASH_FLOAT) || this.hasCashFundPermission) {
                    if (loadModal) {
                        this.isCashFloatModalOpen = !hasCashFloatData;
                    }
                }

                if (hasCashFloatData) {
                    this.setCashFloat(data);
                    this.setHasCashFloat(hasCashFloatData);
                    this.setTerminalsCashFund(terminalCashFund);
                }
            } catch (e) {
                console.error(e);
            }
        },

        async getReceiptDetails() {
            try {
                const response = await getReceiptDetails();
            } catch (e) {
                console.error(e);
            }
        },

        async saveCashFloatFromModal(cashFloat) {
            if (!(await checkCloudConnection())) {
                this.$swal.warning('No internet Connection, Kindly connect to the internet to save the cash fund.');
                bus.emit("isCashFloatSubmitBtnDisabled", false);
                return;
            }

            if (!this.$can(this.PERMISSIONS.SET_CASH_FLOAT) && !this.hasCashFundPermission) return;

            try {
                const terminalId = this.terminalId;
                const response = await saveCashFloat(cashFloat, terminalId);
                this.setCashFloat({ [terminalId]: cashFloat });
                this.setHasCashFloat(true);
                this.isCashFloatModalOpen = false;

                // refresh cash float cache
                if (this.hasCashFundPermission) {
                    await this.fetchCashFloat(false);
                } else {
                    await getCashFloat();
                }

                if (response.data.cashFloat) {
                    this.printFloatReceipt(response.data.cashFloat);
                }
                bus.emit("isCashFloatSubmitBtnDisabled", true);
            } catch (e) {
                bus.emit("isCashFloatSubmitBtnDisabled", false);
                if (e?.response?.status >= 400) {
                    this.$swal.error(e.response.data);
                    return;
                }
                console.error(e);
            }
        },

        bumpLastMtUpdateTimeStamp() {
            this.lastMtUpdateTimeStamp = new Date().toISOString();
        },

        resetIsSyncingFlag() {
            this.setSettlements(this.settlements.map(settlement => ({ ...settlement, isSyncing: settlement.isSync })));
        },

        async backgroundSynching() {
            if (ENABLE_FORCE_SYNC && (!this.isIdle || window.hasActiveBroadcast)) return;

            const unsyncedSettlements = this.settlements
                .filter(settlement => !settlement.isSync && !(settlement.isSyncing && settlement.syncBatch === this.lastRefreshTimestamp))
                .filter(s => moment(s.settledAt).isSameOrBefore(moment().subtract(POST_SETTLEMENT_SYNC_DELAY_SECONDS, 'seconds')));
            let syncingSettlements = this.settlements.filter(settlement => settlement.isSyncing && settlement.syncBatch === this.lastRefreshTimestamp);

            const isStillSyncing = syncingSettlements.length >= MAX_PARALLEL_SYNC;
            const noUnsyncSettlements = isEmpty(unsyncedSettlements);

            if (isStillSyncing || noUnsyncSettlements) return;

            if (!(await checkCloudConnection())) return;

            if(ENABLE_LOCATION_LOGGING && !noUnsyncSettlements) {
              const unsyncSettlementIds = [...unsyncedSettlements].map(settlement => settlement.orderId)
              this.saveAuditLog({ isStillSyncing, noUnsyncSettlements, unsyncSettlementIds });
            }

            let syncBatch = [...unsyncedSettlements];
            if (syncBatch.length + syncingSettlements.length > MAX_PARALLEL_SYNC) {
                syncBatch = syncBatch.slice(0, MAX_PARALLEL_SYNC - syncingSettlements.length);
            }

            const orders = await Promise.all(unsyncedSettlements.map(settlement => this.getOrderByBillNum(settlement.bill_num)));
            const syncOrders = orders.filter(item => item).map(o => isEmpty(o?.splits) ? [o] : o.splits).flat();

            if (syncOrders.length == 0) return;

            const syncOrderBillNums = uniq(syncOrders.map(o => o.bill_num));
            syncBatch.push(...syncOrderBillNums.map(billNum => unsyncedSettlements.find(settlement => settlement.bill_num === billNum)).filter(Boolean));
            syncBatch = uniqBy(syncBatch, 'bill_num');

            if(navigator.onLine) {
                this.setSyncStatus(SYNC_STATUSES.SYNCING)
            }

            syncBatch.forEach(async unsynchSettlement => {
                if (unsynchSettlement.isSync || (unsynchSettlement.isSyncing && unsynchSettlement.syncBatch == this.lastRefreshTimestamp)) return;

                const targetOrder = await this.getOrderByBillNum(unsynchSettlement.bill_num);
                if (!(targetOrder && targetOrder.isSettled)) return;

                try {
                    const update = {
                        isSyncing: true,
                        syncBatch: this.lastRefreshTimestamp,
                        isFromBackground: true
                    };

                    this.updateSettlementByBillNum({
                        billNum: unsynchSettlement.bill_num,
                        settlement: update,
                    });

                    this.updateOrder({
                        orderId: targetOrder._id,
                        order: update,
                    });

                    // TODO: Always use this when tested that non_split don't break
                    if (targetOrder.splits && targetOrder.splits.length > 1 || USE_SPLIT_LOGIC_FOR_NON_SPLIT_ORDERS) {
                        const syncOrders = isEmpty(targetOrder.splits) ? [targetOrder] : targetOrder.splits.map(split => ({ ...targetOrder, ...split, splits: undefined }));
                        for (let i = 0; i < syncOrders.length; i++) {
                            if (syncOrders[i].bill_num != unsynchSettlement.bill_num) continue;

                            const [voided, originals] = partition(syncOrders[i].orders, order => order.originLineItemId);
                            voided.forEach(v => {
                                const originalindex = originals.findIndex(o => o._id === v.originLineItemId);
                                if (originalindex !== null) { //change it to !==null since index 0 will treat as false
                                    originals[originalindex].voidedQty ??= 0;
                                    originals[originalindex].voidedQty += v.quantity;
                                    originals[originalindex].quantity += v.quantity;
                                    originals[originalindex].isCancelled = v.isCancelled;
                                    originals[originalindex].isVoided = v.isVoided;
                                }
                            });

                            const billDiscount = syncOrders[i].billDiscount ?? syncOrders[i].discounts[0] ?? null;

                            const kotBreakdowns = syncOrders[i].orders.reduce((acc, order) => {
                                const existingBreakdownIndex = acc.findIndex(b => b.kotNum == order.kot);
                                let totals = order.totals;
                                if (billDiscount && APPLY_DISCOUNT_TO_KOT_BREAKDOWNS) {
                                    const discountAmount = calculateDiscountAmount(billDiscount, totals, syncOrders[i].pax);
                                    totals = applyDiscount(billDiscount, totals, discountAmount, syncOrders[i].pax);
                                }

                                if (existingBreakdownIndex > -1) {
                                    Object.keys(totals).forEach(key => {
                                        if (['isScInclusive', 'scIsAfterDiscount', 'kotNum'].includes(key)) return;
                                        acc[existingBreakdownIndex][key] += totals[key];
                                    });
                                } else {
                                    acc.push({
                                        ...totals,
                                        kotNum: order.kot,
                                    });
                                }

                                return acc;
                            }, []);

                            const payload = {
                                ...unsynchSettlement,
                                ...syncOrders[i],
                                splits: undefined,
                                breakdown: syncOrders[i].totals,
                                change: syncOrders[i].payments.reduce((acc, payment) => acc + payment.amount, 0) - syncOrders[i].totals.total,
                                orders: [{
                                    ...unsynchSettlement.orders[0],
                                    ...syncOrders[i],
                                    billDiscount,
                                    orders: originals.map(lineItem => ({
                                        ...lineItem,
                                        totals: lineItem.preDiscountTotals ?? lineItem.totals,
                                    })),
                                    kotBreakdowns,
                                    payments: redistributeTenders(syncOrders[i].payments, syncOrders[i].totals.total),
                                    splitIndex: i,
                                }],
                            };
                            await backgroundSync(payload);
                        }
                    } else {
                        const [voided, originals] = partition(targetOrder.orders, order => order.originLineItemId);
                        voided.forEach(v => {
                            const originalindex = originals.findIndex(o => o._id === v.originLineItemId);
                            if (originalindex !== null) { //change it to !==null since index 0 will treat as false
                                originals[originalindex].voidedQty ??= 0;
                                originals[originalindex].voidedQty += v.quantity;
                                originals[originalindex].quantity += v.quantity;
                                originals[originalindex].isCancelled = v.isCancelled;
                                originals[originalindex].isVoided = v.isVoided;
                            }
                        });

                        const payload = {
                            ...unsynchSettlement,
                            orders: unsynchSettlement.orders.map((o, splitIndex) => ({
                                ...o,
                                orders: originals.map(lineItem => ({
                                    ...lineItem,
                                    totals: lineItem.preDiscountTotals ?? lineItem.totals,
                                })),
                                splitIndex,
                            })),
                            payments: redistributeTenders(unsynchSettlement.payments, unsynchSettlement.breakdown.total),
                        };

                        await backgroundSync(payload);
                    }
                } catch (e) {
                    console.error('Sync failed', e);
                    this.updateSettlementByBillNum({
                        billNum: unsynchSettlement.bill_num,
                        settlement: { isSyncing: false },
                    });

                    this.updateOrder({
                        orderId: targetOrder._id,
                        order: { isSyncing: false, isFromBackground: true },
                        forceFullBroadcast: true,
                    });

                    return;
                }

                //update is_sync
                unsynchSettlement.isSync = false;
                unsynchSettlement.isSyncing = false;
                this.updateSettlementByBillNum({
                    billNum: unsynchSettlement.bill_num,
                    settlement: { isSync: true, isSyncing: false },
                });

                try {
                    await storeSyncedOrder(trimSyncedOrder({ ...targetOrder, isSync: true, isSyncing: false }));
                    if (!DELETE_SETTLEMENT_ON_SYNC) {
                        await storeSyncedSettlement(unsynchSettlement);
                    }
                    await this.updateOrder({
                        orderId: targetOrder._id,
                        order: { isSync: true, isSyncing: false, isFromBackground: true },
                    });
                    this.deleteOrder(unsynchSettlement.orderId);
                    this.deleteSettlementByBillNum(unsynchSettlement.bill_num);
                } catch (e) {
                    console.error(e);
                }
            });

            setTimeout(() => {
                this.updateProgress();
            }, 3000);
        },

        async updateProgress() {
            const syncedOrders = await getSyncedOrders(this.posDate);
            const unsyncedOrders = this.orders.filter(o => o && o.isBilled && !o.isSynced);
            const syncedUpdates = this.orders.filter(o => o && o.isUpdateSynced);
            const syncedCount = syncedOrders.length + syncedUpdates.length;
            const totalCount = syncedCount + unsyncedOrders.length;

            const totalProgressPercent = totalCount ? Math.round((syncedCount / totalCount) * 100) : 0;

            this.setSyncProgressPercent(totalProgressPercent);
        },

        async updateSyncing() {
            if (!this.isIdle || (ENABLE_FORCE_SYNC && window.hasActiveBroadcast)) return;

            const syncedOrders = await getSyncedOrders(this.posDate);
            const pendingAndBilledVoid = this.orders.filter(p => p && p.isVoided === true && !p.isUpdateSynced && !p.isUpdateSyncing);
            const unsyncedPaidVoid = syncedOrders.filter(o => o && o.isVoided && !o.isUpdateSynced && !o.isUpdateSyncing);
            const unsyncedUpdates = unsyncedPaidVoid.concat(pendingAndBilledVoid);
            let finalUnsyncUpdates = [];
            const queueStatusValues = !isEmpty(this.queueStatus) ? [...new Set(Object.values(this.queueStatus))] : [];
            if (isEmpty(unsyncedUpdates) && !queueStatusValues.includes(QUEUE_STATUSES.UNSYNCED)) {
                this.setSyncStatus(SYNC_STATUSES.SYNCED);
                return;
            }

            //include splits
            unsyncedUpdates.forEach(u => {
                if(u.splits && u.splits.length > 0) {
                    //loop through splits
                    u.splits.forEach(s => {
                        finalUnsyncUpdates.push(s);
                    });
                } else {
                    finalUnsyncUpdates.push(u);
                }
            });

            for (let uu of finalUnsyncUpdates) {
                uu.updateType ??= uu.isVoided ? 'void' : 'update';
                const [voided, originals] = partition(uu.orders, order => order.originLineItemId);
                voided.forEach(v => {
                    const originalIndex = originals.findIndex(o => o._id === v.originLineItemId);
                    if (originalIndex) {
                        originals[originalIndex].voidedQty ??= 0;
                        originals[originalIndex].voidedQty += v.quantity;
                        originals[originalIndex].quantity += v.quantity;
                    }
                });
                uu.orders = originals;

                switch (uu.updateType) {
                    case 'void':
                        if(navigator.onLine) {
                            this.setSyncStatus(SYNC_STATUSES.SYNCING)
                        }

                        await this.voidSyncedOrder(uu);
                        break;
                    default:
                        // do nothing
                        break;
                }
            }
        },

        async voidSyncedOrder(order) {
            if (order.isUpdateSynced || order.isUpdateSyncing) return;

            if (!(await checkCloudConnection())) return;

            if(order.isSettled) {
                await updateSyncedOrder(order._id, { ...order, isUpdateSyncing: true });
            } else {
                this.updateOrder({
                    orderId: order._id,
                    order: { ...order, isUpdateSyncing: true, isFromBackground: true },
                });
            }

            try {
                order.kots = uniq(order.orders.map(o => o.kot))
                order.orders = order.orders.map(lineItem => ({
                    ...lineItem,
                    totals: lineItem.preDiscountTotals ?? lineItem.totals,
                }))
                await voidBackgroundSync(order);
                if(order.isSettled) {
                    await updateSyncedOrder(order._id, { ...order, isUpdateSynced: true });

                    this.updateUnsyncVoidedBillCount();
                } else {
                    this.updateOrder({
                        orderId: order._id,
                        order: { ...order, isUpdateSynced: true, isFromBackground: true },
                    });
                }
            } catch (e) {
                console.error(e);
            }
        },

        async subscribeToServerEvents() {
            try {
                subscribeToOrderUpdates(async orders => {
                    orders.forEach(order => this.upsertOrder({ orderId: order._id, order, isFromBroadcast: true }));
                });
            } catch (e) {
                console.error(e);
                this.$swal.error('Error setting up multiterminal', e.message);
            }
        },

        async saveAuditLog(contents) {
            const params = {
                locationId: window.locationId,
                billingId: null,
                transactionId: null,
                terminalId: tempTerminalId,
                title: 'Background Syncing',
                action: 'Background Syncing',
                userId: window.userId,
                businessDate: tempPosDate,
                description: JSON.stringify(contents)
            }

            try {
                await storeAuditLog(params, this.isAyalaLocation);
            } catch(e) {
                console.error(e);
            }
        },
    },

    watch: {
        latestDeliveryOrders(newValues, oldValues) {
            if (this.$tempTerminalId != 1) return;
            const ob = new OrderBridge();
            const canPrintLabels = this.$can(PERMISSIONS.LABEL_PRINTING);
            const newDeliveries = differenceBy(newValues, oldValues, 'id');

            if (newDeliveries.length) {
                this.setLatestDeliveryNotifications(newDeliveries);
                this.notificationAudio.play();

                newDeliveries.forEach(async delivery => {
                    if (delivery.trigger === 'auto') {
                        let kots = delivery?.kots

                        if (OFFLOAD.sqliteOffloadReceipt) {
                            const response = await ob.getOrderById(parseInt(delivery.id));
                            const pb = new ProductBridge();
                            const order = JSON.parse(response.order_data);
                            kots = order.kots;

                            // The products here do not include the label_printing
                            // flag. We need to check SQLite to see if these
                            // products are configured for label printing.
                            const promises = order.orders.map(async o => {
                                console.log('Filling in order data for:', o.product);
                                const response = await pb.getProducts({id: o.product.id, page: 1, itemsPerPage: 1});
                                o.product = response.data[0];
                            });
                            await Promise.all(promises);

                            const hasLabelPrint = order.orders.some(o => o.product.label_printing);

                            if (canPrintLabels && hasLabelPrint) {
                                console.log('Performing automatic label print for delivery order');
                                for (const kot of kots) {
                                    await this.processPrintLabel(order, kot);
                                }
                            }
                        }

                        this.printAllKOT(delivery.id, kots)
                    }
                });
            }
        },

        latestCancelledDeliveryOrders(newValues, oldValues) {
            if(this.$tempTerminalId != 1) return;

            const newCancelledDeliveries = differenceBy(newValues, oldValues, 'id');

            if(newCancelledDeliveries.length) {
                this.setLatestCancelledDeliveryNotifications(newCancelledDeliveries);
                this.notificationAudio.play();

                newCancelledDeliveries.forEach(delivery => {
                    if(delivery.isAccepted) {
                        this.printAllVoidedReceipts(delivery.id, delivery?.kots)
                    }
                });
            }
        },

        async latestOnlinePayment(newValue) {
            console.log(`BasePage - latestOnlinePayment is called (this part handles the redirect and notification sound after payment confirmation.)`);
            console.log(`newValue: `, newValue);

            if(this.$tempTerminalId != 1) return;

            let order;
            let isFullyPaid = newValue.isFullyPaid;
            if (OFFLOAD.sqliteOffloadReceipt) {
                const ob = new OrderBridge();
                const orderResponse = await ob.getOrderById(parseInt(newValue.orderId));
                order = JSON.parse(orderResponse.order_data);
            } else {
                order = this.orders.find((order) => order._id === newValue.orderId);

                if(!order) {
                    const syncedOrders = await getSyncedOrders(this.posDate);
                    order = syncedOrders.find((order) => order._id === newValue.orderId)
                }

                isFullyPaid = isFullyPaid && (order?.splits?.length <= 1 || !order.hasOwnProperty('splits'));
            }

            if(isFullyPaid) {
                Logger.info(`Initialize print receipt: latestOnlinePayment`)
                this.setLatestOnlinePaymentNotifications(newValue);
                this.notificationAudio.play();

                // for paymongo, allow cashier to manually click & print settlement receipt
                if (newValue.type == "paymongo") {
                    // hide the QRCode Modal
                    this.setShowPaymentQRModal(false);

                    this.$customSwal({
                        stackedIcons: [
                            { src: '/images/mosaicpay-qrph.svg', alt: 'Mosaic Logo' },
                            { src: '/images/icon_verified-check.svg', alt: 'Payment Received Icon' },
                        ],
                        title: "Payment Received",
                        text: `₱ ${newValue.amount}`,
                        customClass: {
                            icon: 'no-border-icon',
                            title: 'custom-title-class',
                            content: 'custom-content-class',
                        },
                        buttonText: "OK",
                        className: 'center-btn'
                    });

                    return;
                }

                // only auto-print settlement receipt and redirect to home if epayment is xendit
                this.printReceipt("settlement", order);

                if(this.$route.name === 'payments' && this.$route.params.orderId == newValue.orderId) {
                    this.$router.push({ name: 'home' });

                    if(this.showPaymentQRModal) {
                        this.setPaymentQRDetails({
                            ...this.paymentQRDetails,
                            status: newValue.status,
                        });
                    }
                }
            }
        },

        async hasCashFundPermission(value) {
            if (value) {
                await this.fetchCashFloat();
            }
        },

        latestDineInOrders(newValues, oldValues) {
            if(this.$tempTerminalId != 1) return;

            const newOrders = differenceBy(newValues, oldValues, 'id');

            if(newOrders.length) {
                this.setLatestDeliveryNotifications(newOrders);
                this.notificationAudio.play();

                newOrders.forEach(newOrder => {
                    if(newOrder.trigger === 'auto') {
                        this.printAllKOT(newOrder.id, newOrder?.kots);
                    }
                });
            }
        },

        async latestDineInOnlinePayment(newValue) {
          if(this.$tempTerminalId != 1) return;

          this.setLatestOnlinePaymentNotifications(newValue);
          this.notificationAudio.play();

          let order = this.orders.find((order) => order._id == newValue.order_id);

          if(!order) {
            const syncedOrders = await getSyncedOrders(this.posDate);
            order = syncedOrders.find((order) => order._id == newValue.order_id)
          }

          await this.printReceipt("tabsquare", order);
        },
    }
}
</script>
