import moment from 'moment';
import { DataOffloadBridge, LocationBridge, ReportReadingBridge } from './offload';
import { ReceiptBridge } from './offload-receipt';

export class SalesFileUtilityBridge extends DataOffloadBridge {
  constructor() {
    super('SalesFileUtility');
  }

  isSupported() {
    return window.MosaicPosAppVersionNumbers?.versionCode >= 67;
  }

  async createDayEndFiles() {
    return await this.sendMessage('CREATE_DAY_END_FILES');
  }

  async createHourlyFiles() {
    return await this.sendMessage('CREATE_HOURLY_FILES');
  }

  async testNetworkShareSettings() {
    return await this.sendMessage('TEST_NETWORK_SHARE_SETTINGS');
  }

  async runFileSync() {
    return await this.sendMessage('RUN_FILE_SYNC');
  }

  async forceGenerateLatestSalesFiles() {
    return await this.sendMessage('FORCE_GENERATE_LATEST_SALES_FILES');
  }

  async runQueryTests() {
    return await this.sendMessage('RUN_QUERY_TESTS');
  }

  async getReprocessableDays() {
    const rb = new ReceiptBridge();
    const rrb = new ReportReadingBridge();

    const receipts = await rb.getAll();
    const reportReadings = await rrb.getAll();
    const dateFormat = 'YYYY-MM-DD';

    const receiptDates = receipts.rows.map(r => r.settled_at)
      .filter(date => !!date)
      .map(date => moment(date).format(dateFormat));
    const rrDates = reportReadings.rows.map(
      rr => moment(rr.business_date).format(dateFormat)
    );

    return rrDates.filter(
      (date) => receiptDates.includes(date)
    ).filter(d => d > '2024-01-31');
  }

  async regenerateAndResendForDate(date) {
    return await this.sendMessage('REGENERATE_AND_RESEND_FOR_DATE', { date });
  }

  async runHourlyGenerationCheck() {
    return await this.sendMessage('RUN_HOURLY_GENERATION_CHECK');
  }
}

export class SalesFileSendStatusBridge extends DataOffloadBridge {
  constructor() {
    super('SalesFileSendStatus');
  }
}
