// BRIDGES FOR REPORT & SALES FILE RELATED DATA

import { DataOffloadBridge } from './offload';

export class ReportDataBridge extends DataOffloadBridge {
  constructor() {
    super('ReportData');
  }

  async retrieveFromBackend() {
    const storage = new ScopedNativeStorage(window.locationId);
    const lastFetchAt = await storage.get('reportDataLastFetchAt');
    const response = await axios.get('/cashier/offline_items/report_data', {
      headers: { 'Content-Type': 'application/json' },
      params: {
        lastFetchAt,
        enableSqliteIntegration: 1,
        appVersionCode: window.MosaicPosAppVersionNumbers?.versionCode
      }
    });

    const rows = response.data[0].values;
    console.log('retrieveFromBackend: Adding report_data rows', rows);
    await this.bulkImport(rows);
  }

  async query(params) {
    return await this.sendMessage('QUERY', { params });
  }

  async update(payloads) {
    return await this.sendMessage('UPDATE', { payloads });
  }

  async getBatchForSync() {
    return this.query({
      whereNull: ['synced_at'],
      orderBy: 'created_at ASC'
    });
  }  
}

export class AccreditationBridge extends DataOffloadBridge {
  constructor() {
    super('Accreditation');
  }

  async query(params) {
    return await this.sendMessage('QUERY', { params });
  }

  async getBatchForSync(batchSize = 100) {
    return this.query({
      whereNull: ['synced_at'],
      orderBy: 'created_at ASC',
      limit: batchSize
    });
  }
}

export class AccreditationTypeBridge extends DataOffloadBridge {
  constructor() {
    super('AccreditationType');
  }
}

export class TaxDetailBridge extends DataOffloadBridge {
  constructor() {
    super('TaxDetail');
  }
}

export class TaxLocationBridge extends DataOffloadBridge {
  constructor() {
    super('TaxLocation');
  }
}

export class TaxTypeBridge extends DataOffloadBridge {
  constructor() {
    super('TaxType');
  }
}

export class ShiftChangeBridge extends DataOffloadBridge {
  constructor() {
    super('ShiftChange');
  }

  async getShiftChangeById(id) {
    return await this.sendMessage('GET_SHIFT_CHANGE_BY_ID', { id });
  }

  async getShiftChangesByDate(businessDate) {
    return await this.sendMessage('GET_SHIFT_CHANGES_BY_DATE', { businessDate });
  }

  async insertShiftChange(shiftChange) {
    return await this.sendMessage('INSERT_SHIFT_CHANGE', { shiftChange });
  }

  async deleteShiftChange(id) {
    return await this.sendMessage('DELETE_SHIFT_CHANGE', { id });
  }
}

export class LocationPosCashBridge extends DataOffloadBridge {
  constructor() {
    super('LocationPosCash');
  }

  async getByLocationId(locationId) {
    return await this.sendMessage('GET_BY_LOCATION_ID', { locationId });
  }

  async upsert(locationPosCash) {
    return await this.sendMessage('UPSERT', { locationPosCash });
  }
}

export class PaymentCategoryBridge extends DataOffloadBridge {
  constructor() {
    super('PaymentCategory');
  }
}

export class ServiceTypeBridge extends DataOffloadBridge {
  constructor() {
    super('ServiceType');
  }
}
