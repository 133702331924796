import axios from "@/spa/plugins/axios";
import originalAxios from 'axios';
import localforageOriginal from 'localforage';
import cloneDeep from 'rfdc/default';
import { noop, uniqBy } from 'lodash';

import { checkIfOrderUnsynced } from '@/spa/services/unsync-tracker-service';
import { createSafeLocalForageSet } from '@/spa/utils/local-storage';
import {OFFLOAD} from "@/spa/constants";

var localForage = localforageOriginal.createInstance({ name: 'synced-data' });
const safeLocalForageSet = createSafeLocalForageSet(localForage);

// we use a function to ensure userId is always up to date
const GET_SYNCED_ORDERS_KEY = (orderId = '') => `synced-orders-${window.locationId}-${orderId}`;
const GET_SYNCED_SETTLEMENTS_KEY = (orderId = '') => `synced-settlements-${window.locationId}-${orderId}`;
const REMOVE_ACTIVE_BROADCAST_SECONDS = 10;

export const syncSettlements = (settlements) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return axios.post(route("offline.sync"), settlements, { headers });
};

export const backgroundSync = (settlements) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return axios.post(route("offline.background.sync"), settlements, { headers });
};

export const sqliteOffloadSync = (payload) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const receiptIdsJoined = payload.receipts.map(r => r.receipt_num).join('-');

  payload.hasEPayment = window.hasEPayment;

  return axios.post(route("sync.offload.orders") + `/${window.locationId}/${receiptIdsJoined}`, payload, { headers });
};

export const getSupportScript = (params) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return originalAxios.get(route("get.support.scripts"), {
    headers,
    params,
  });
};

export const updateSupportScript = (payload) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return originalAxios.post(route("update.support.scripts"), payload, { headers });
};

export const storeSyncedOrder = async order => {
  return safeLocalForageSet(GET_SYNCED_ORDERS_KEY(order._id), order);
}

export const updateSyncedOrder = async (orderId, order, isDelete = false, shouldBroadcast = false) => {
  if(isDelete) {
    return localForage.removeItem(GET_SYNCED_ORDERS_KEY(orderId));
  } else {
    const oldOrder = await localForage.getItem(GET_SYNCED_ORDERS_KEY(orderId));

    const newOrder = {
      ...oldOrder,
      // need to go last so sync flags can be set
      ...cloneDeep(order),
    };

    newOrder.orders = uniqBy(newOrder.orders, '_id');
    if (shouldBroadcast) {
      broadcastOrderUpdate(newOrder);
    }

    return safeLocalForageSet(GET_SYNCED_ORDERS_KEY(orderId), newOrder);
  }
}

export const getSyncedOrders = async (posDate = null) => {
  const prefix = GET_SYNCED_ORDERS_KEY();
  const keys = await localForage.keys();
  const syncedOrderKeys = keys.filter(key => key.startsWith(prefix));
  const orders = await Promise.all(syncedOrderKeys.map(key => localForage.getItem(key)));
  if (posDate === null) return orders;

  return orders.filter(order => order && order.originShiftTable?.pos_date === posDate);
}

export const cleanupOlderOrders = async (currentPosDate = null) => {
  const prefix = GET_SYNCED_ORDERS_KEY();
  const keys = await localForage.keys();
  const syncedOrderKeys = keys.filter(key => key.startsWith(prefix));

  const tasks = [];

  for (const key of syncedOrderKeys) {
    const order = await localForage.getItem(key);

    if (currentPosDate === null || order.originShiftTable.pos_date < currentPosDate) {
      tasks.push(localForage.removeItem(key));
    }
  }

  return Promise.all(tasks);
}

export const cleanupOlderSettlements = async (currentPosDate = null) => {
  const prefix = GET_SYNCED_SETTLEMENTS_KEY();
  const keys = await localForage.keys();
  const syncedOrderKeys = keys.filter(key => key.startsWith(prefix));

  const tasks = [];

  for (const key of syncedOrderKeys) {
    const orderId = key.replace(prefix, '');

    const order = await localForage.getItem(GET_SYNCED_ORDERS_KEY(orderId));

    if (currentPosDate === null || order.originShiftTable.pos_date  < currentPosDate) {
      tasks.push(localForage.removeItem(key));
    }
  }

  return Promise.all(tasks);
}

export const storeSyncedSettlement = async settlement => {
  return safeLocalForageSet(GET_SYNCED_SETTLEMENTS_KEY(settlement.orderId), settlement);
}

export const getSyncedSettlements = async () => {
  const prefix = GET_SYNCED_SETTLEMENTS_KEY();
  const keys = await localForage.keys();
  const syncedOrderKeys = keys.filter(key => key.startsWith(prefix));
  return Promise.all(syncedOrderKeys.map(key => localForage.getItem(key)));
}

export const voidBackgroundSync = (voids) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return axios.post(route("offline.void.background.sync"), voids, { headers });
};

export const shiftChangeSync = async (data, isAyalaLocation) => {
  const stb = new ShiftTableBridge();
  data.sqliteOffloadShiftTable = await stb.isEnabled();

  if(isAyalaLocation) {
    const generateReportBridge = new GenerateReportBridge();
    return await generateReportBridge.shiftChangeSync(data);
  }

  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(route("offline.shift.change.sync"), data, { headers });
};

export const storeShiftTable = (data) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return axios.post(route("store.shift.table"), data, { headers });
};

export const broadcastOrderUpdate = async (order) => {
  if (!order._id || window.enableOfflineMode) return;

  const headers = {
    "Content-Type": "application/json",
  };

  window.hasActiveBroadcast = true;
  try {
      const terminal = JSON.parse(sessionStorage.getItem('terminal'));
      order.updatedByTerminalId = terminal.id
      return axios.put(route("orders.broadcast.offline") + `/${window.locationId}`, order, { headers });
  } catch (e) {
      //do nothing
  } finally {
      setTimeout(() => {
          window.hasActiveBroadcast = false;
      }, REMOVE_ACTIVE_BROADCAST_SECONDS * 1000);
  }
};

export const getLatestOrders = (lastFetchTimeStamp = undefined, queueStatusOnly = false) => {
  if (window.enableOfflineMode) return Promise.resolve({ data: { orders: [], queueStatus: []} });

  return originalAxios.get(route("orders.pending.offline"), { cache: { maxAge: 0 }, params: { lastFetchTimeStamp, queueStatusOnly } });
}

export const getDateRangeOrders = (startDate, endDate) => {
  return axios.get(route("orders.pending.offline"), { cache: { maxAge: 0 }, params: { startDate, endDate } });
}

export const forceSyncOrder = async (posDate, orders) => {
  if (window.enableOfflineMode || OFFLOAD.sqliteOffloadReceipt) return;

  // get all synced/settled orders on device and process it in cloud
  const syncOrders = await getSyncedOrders(posDate);

  let hasUpdated = false;
  const syncOrdersPromises = syncOrders.map(async order => {
    const isUnsynced = checkIfOrderUnsynced(order._id);
    if (!order.locationId || order.locationId !== window.locationId || !isUnsynced) return;
    await addMissingSyncedOrder(order);
    hasUpdated = true;
  });

  await Promise.all(syncOrdersPromises);

  // get all ongoing orders on device and process it in cloud
  const ordersPromises = orders.map(async order => {
    const isUnsynced = checkIfOrderUnsynced(order._id);
    if (!order.locationId || order.locationId !== window.locationId || !isUnsynced) return;
    await addMissingSyncedOrder(order);
    hasUpdated = true;
  });

  await Promise.all(ordersPromises);

  if (!hasUpdated) return { data: { orders: [] } };

  // get all updated transactions in cloud and store it to device
  return axios.get(route("force.sync.offline.orders"), { cache: { maxAge: 0 } });
}

export const syncOrder = async (order) => {
  // skip if sqliteOffloadReceipt is enabled
  if (OFFLOAD.sqliteOffloadReceipt) {
      return;
  }

  const headers = {
    "Content-Type": "application/json",
  };

  try {
      return axios.post(route("sync.offline.order"), order, { headers });
  } catch (e) {
      console.error(e);
  }
}

export const updateOrderReprintCount = async (order) => {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    return axios.post(route("receipt.orders.updateReprintCount"), order, { headers });
  } catch (e) {
    console.error(e);
  }
}

const addMissingSyncedOrder = async (order) => {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    return axios.post(route("add.missing.offline.order"), order, { headers });
  } catch (e) {
    console.error(e);
  }
};

export const subscribeToOrderUpdates = (successCallback, errorCallback = noop) => {
  if (typeof (EventSource) === "undefined") {
    throw new Error('Sorry, your browser does not support multi-terminal functionality');
  }

  const terminal = JSON.parse(sessionStorage.getItem('terminal'))
  if (!terminal?.is_shared_terminal) return;

  const source = new EventSource(route("orders.subscribe.offline"));

  source.addEventListener('message', event => event.data && successCallback(JSON.parse(event.data)), false);
  source.addEventListener('error', event => {
      if (event.readyState === EventSource.CLOSED) {
          console.log('eventSource: ', EventSource);
      }

      errorCallback(event);
  }, false);
}

export const deleteOfflineOrder = (orderId) => {
  return axios.delete(route("orders.delete.offline", { orderId }));
}

export const getLatestSeries = () => {
  return originalAxios.get(route("orders.series.offline"));
}

export default {
  syncSettlements,
  backgroundSync,
  voidBackgroundSync,
  storeSyncedOrder,
  updateSyncedOrder,
  getSyncedOrders,
  storeSyncedSettlement,
  getSyncedSettlements,
  broadcastOrderUpdate,
  deleteOfflineOrder,
  getDateRangeOrders,
  getLatestOrders,
  getLatestSeries,
  subscribeToOrderUpdates,
  cleanupOlderOrders,
  cleanupOlderSettlements,
};
