<template>

</template>
<script>
import { mapActions, mapMutations } from 'vuex';
import { multiterminalEvents } from '@/mobile_bridge/multiterminal/events';
import { MultiterminalUtilityBridge } from '@/mobile_bridge/multiterminal/bridges';
import { cashierLogout } from "@/spa/services/cashier-service";
import { OrderBridge } from '../../../mobile_bridge/offload/offload-receipt';
import { SalesFileUtilityBridge } from '../../../mobile_bridge/offload/accreditations';
import { LocationBridge } from '../../../mobile_bridge/offload/offload';

export default {
  data() {
  },
  mounted() {
    const mub = new MultiterminalUtilityBridge();
    let multiterminalStatus = null;
    const terminalJson = sessionStorage.getItem('terminal');
    const terminal = JSON.parse(terminalJson || '{}');

    mub.getStatus().then(status => {
      multiterminalStatus = status;
    });

    window.addEventListener('message', event => {
      const mtEvent = event.data;

      if (!mtEvent.mosaicBridgeMessage) {
        return;
      }

      switch (mtEvent.type) {
        case 'Multiterminal.MessageFeed':
          if (terminal.id !== mtEvent.body.terminalId) {
            multiterminalEvents.emit('MessageFeed', mtEvent);
          }
          break;
        case 'Multiterminal.Satellite.PrimaryFound':
          this.handlePrimaryFound(mtEvent.body);
          break;
      }
    });

    multiterminalEvents.on('MessageFeed', async (event) => {
      const message = event.body;
      const status = multiterminalStatus;

      if (status?.mode === 'satellite') {
        if (message.action === 'FORCE_SATELLITE_LOGOUT') {
          // log out!
          console.log('Force logout message received!');
          await cashierLogout();
          window.location.href = '/cashier/home';
        }
      }

      switch (message.type) {
        case 'Order':
          console.log('Handling Order message', message);
          this.handleOrderActions(message);
          break;
      }
    });

    // Piggybacking on this for now: Native sales file periodic sync retry
    this.setupSalesFileTasks();
  },
  methods: {
    ...mapMutations(['addOrder']),
    ...mapActions(['upsertOrder']),

    async handleOrderActions(message) {
      const { action } = message;
      const ob = new OrderBridge();

      switch (action) {
        case 'UPSERT': {
          console.log('Handling received Order.UPSERT message', message);
          const orders = await ob.getAll();
          
          for (const order of orders.rows) {
            const oj = JSON.parse(order.order_data);

            console.log('Upserting order', oj._id, oj);
            this.upsertOrder({
              orderId: oj._id,
              order: oj,
              isFromBroadcast: true
            });
          }
          break;
        }
      }
    },

    handlePrimaryFound(messageBody) {
      console.log('Primary found:', messageBody);
    },

    async setupSalesFileTasks(multiterminalStatus) {
      const sfub = new SalesFileUtilityBridge();
      const everyFiveMinutes = 60 * 5 * 1000;
      const locationBridge = new LocationBridge();
      const locations = await locationBridge.getAll();

      const isPrimary = multiterminalStatus?.mode === 'primary';
      const isAyalaAccredited = locations[0]?.accreditation_type_id == 2;

      if (sfub.isSupported() && isPrimary && isAyalaAccredited) {
        setInterval(async () => {
          console.log('Checking for and syncing any unsynced sales files...');
          sfub.runFileSync();
        }, everyFiveMinutes);

        // Running this every 5 minutes lets us promptly catch up to missed
        // hourly generations if the app was left closed for a prolonged period.
        setInterval(() => {
          sfub.runHourlyGenerationCheck();
        }, everyFiveMinutes);
      }
    }
  },
}
</script>
